export default function Moment(value) {
  return {
    _value: value || new Date().toISOString().split('T')[ 0 ],

    value(date) {
      if (date) {
        if (typeof date === 'string') {
          try {
            this._value = new Date(date).toISOString().split('T')[ 0 ];

            return this;
          } catch (e) {
            console.error('[EDate] - set value: Invalid date string');
          }
        } else if (typeof date === 'number') {
          try {
            const string = date.toString().slice(0, 4) + '-' + date.toString().slice(4, 6) + '-' + date.toString().slice(6, 8);
            this._value = new Date(string).toISOString().split('T')[ 0 ];

            return this;
          } catch (e) {
            console.error('[EDate] - set value: Invalid date number');
          }
        } else {
          console.error('[EDate] - set value: Invalid date type');
        }
      } else {
        return this._value;
      }
    },

    date(offset = 12) {
      const _date = new Date(this._value);
      _date.setHours(offset, 0, 0, 0);
      return _date;
    },

    number() {
      return parseInt(this._value.split('-').join(''));
    },

    forEach(to, callback = () => { }) {
      const _to = new Moment(to).date();
      const _from = new Date(this._value);

      for (let date = _from; date <= _to; date.setDate(date.getDate() + 1)) {
        callback(date, date.toISOString().split('T')[ 0 ]);
      }

      return this;
    },

    map(to, callback = () => { }) {
      const _to = new Moment(to);
      const result = [];

      for (let date = new Moment(this._value); date.date() <= _to.date(); date.add(1)) {
        result.push(callback(date));
      }

      return result;
    },

    reduce(to, callback = () => { }, initialValue) {
      if (typeof to === 'string') {
        const _to = new Date(to);
        _to.setHours(12, 0, 0, 0);
        let acc = initialValue;

        for (let date = new Moment(this._value); date.date() <= _to; date.add(1)) {
          acc = callback(acc, date);
        }

        return acc;
      } else if (typeof to === 'number') {
        let acc = initialValue;

        const date = new Moment(this._value);

        for (let i = 0; i <= to; i++) {
          acc = callback(acc, date);
          date.add(1, 'days');
        }

        return acc;
      }
    },

    add(value, type = 'days') {
      if (type === 'days') {
        const _date = this.date();
        _date.setDate(_date.getDate() + value);
        _date.setHours(12, 0, 0, 0);
        this.value(_date.toISOString().split('T')[ 0 ]);
      } else if (type === 'months') {
        const _date = this.date();
        _date.setMonth(_date.getMonth() + value);
        _date.setHours(12, 0, 0, 0);
        this.value(_date.toISOString().split('T')[ 0 ]);
      } else if (type === 'years') {
        const _date = this.date();
        _date.setFullYear(_date.getFullYear() + value);
        this.value(_date.toISOString().split('T')[ 0 ]);
      } else {
        console.error('[EDate] - add: Invalid type');
      }

      return this;
    },

    first(type = 'date') {
      if (type === 'date') {
        const _date = this.date();
        _date.setDate(1);
        this.value(_date.toISOString().split('T')[ 0 ]);
      } else if (type === 'month') {
        const _date = this.date();
        _date.setMonth(0);
        this.value(_date.toISOString().split('T')[ 0 ]);
      } else if (type === 'year') {
        const _date = this.date();
        _date.setMonth(0);
        _date.setDate(1);
        this.value(_date.toISOString().split('T')[ 0 ]);
      } else {
        console.error('[EDate] - first: Invalid type');
      }

      return this;
    },

    last(type = 'date') {
      if (type === 'date') {
        const _date = this.date();
        _date.setMonth(_date.getMonth() + 1);
        _date.setDate(0);
        this.value(_date.toISOString().split('T')[ 0 ]);
      } else if (type === 'month') {
        const _date = this.date();
        _date.setMonth(11);
        this.value(_date.toISOString().split('T')[ 0 ]);
      } else if (type === 'year') {
        const _date = this.date();
        _date.setMonth(11);
        _date.setDate(31);
        this.value(_date.toISOString().split('T')[ 0 ]);
      } else {
        console.error('[EDate] - last: Invalid type');
      }

      return this;
    },

    day() {
      const _date = this.date();
      return _date.getDay() === 0 ? 6 : _date.getDay() - 1;
    },

    week(range = 'year') {
      if(range === 'month') {
        const start = new Moment(this._value).first();
        const end = new Moment(this._value).value();
        const initialValue = 0;

        const weekNumber = start.reduce(end, (acc, date) => {
          return date.day() === 0 ? acc + 1 : acc
        }, initialValue);

        return weekNumber;
      } else {
        return Moment(this.get('year') + '-01-01').reduce(this._value, (acc, date) => date.day() === 0 ? acc + 1 : acc, 0);
      }
    },

    left() {
      return this.duration(this.get('year') + '-12-31');
    },

    monthdays() {
      return new Date(this.get('year'), this.get('month') + 1, 0).getDate();
    },

    get(type = 'date') {
      if (type === 'date') {
        return this.date().getDate();
      } else if (type === 'month') {
        return this.date().getMonth();
      } else if (type === 'year') {
        return this.date().getFullYear();
      } else {
        console.error('[EDate] - get: Invalid type');
      }
    },

    duration(to, blacklist = undefined) {
      if (blacklist) {
        const start = new Date(this._value);
        const end = new Date(to);
        const sievedBlacklist = blacklist.filter(date => new Date(date) >= start && new Date(date) <= end);

        return +(Math.floor((end - start) / (1000 * 60 * 60 * 24)) - sievedBlacklist.length);
      } else {
        const _from = new Date(this._value);
        _from.setHours(12, 0, 0, 0);
        const _to = new Date(to);
        _to.setHours(12, 0, 0, 0);

        return +Math.round((_to - _from) / (1000 * 60 * 60 * 24));
      }
    },

    end(duration, blacklist = undefined) {
      if (blacklist) {
        const start = new Date(this._value);
        let presumedEnd;
        let sievedBlacklist;
        let acc = 0;
        do {
          presumedEnd = Moment(this._value).add((duration - 1 + acc), 'days').date();
          sievedBlacklist = blacklist.filter(date => new Date(date) >= start && new Date(date) <= presumedEnd);
          if (sievedBlacklist.length > acc) {
            acc = sievedBlacklist.length;
          } else {
            break;
          }
        }
        while (true);

        const trueDuration = duration - 1 + acc;
        const trueEnd = Moment(this._value).add(trueDuration, 'days').available(blacklist, "next");

        return trueEnd;
      } else {
        const date = new Moment(this._value);
        date.add(duration - 1);
        return date.value();
      }

    },

    start(duration, blacklist = undefined) {
      if (blacklist) {
        const date = new Moment(this._value);

        let acc = duration;
        for (let i = 1; i < acc; i++) {
          date.add(-1);
          if (blacklist.includes(date.value())) {
            acc++;
          }
        }

        return date.value();
      } else {
        const date = new Moment(this._value);
        date.add(-duration + 1);
        return date.value();
      }
    },

    available(blacklist, verse = "next") {
      if (verse !== "next" && verse !== "prev") {
        console.error('[EDate] - available: Invalid verse');
        return;
      }

      const date = new Moment(this._value);
      while (blacklist.includes(date.value())) {
        if (verse === "next") {
          date.add(1);
        } else {
          date.add(-1);
        }
      }

      return date.value();
    },

    monthname(names = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]) {
      return names[ this.get('month') ];
    },

    dayname(names = [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday" ]) {
      return names[ this.day() ];
    },

    isToday(range = 'day') {
      if (range === 'week') {
        const isCurrentMonth = this.get('month') == new Date().getMonth()
          && this.get('year') == new Date().getFullYear();

        if (!isCurrentMonth) return false;

        const isCurrentWeek = Moment(this._value).add(-this.day()).reduce(6, (acc, date) => {
          if(acc) return acc;
          return date.value() == new Date().toISOString().split('T')[ 0 ];
        }, false);

        return isCurrentWeek;
      } else if (range === 'month') {
        const isCurrentMonth = this.get('month') == new Date().getMonth()
          && this.get('year') == new Date().getFullYear();

        return isCurrentMonth;
      } else {
        return this._value == new Date().toISOString().split('T')[ 0 ];
      }
    },

    same(date, range = 'day') {
      // date is a string
      // range: day, month, year

      if(range === 'month') {
        return this.get('month') == new Date(date).getMonth()
          && this.get('year') == new Date(date).getFullYear();
      } else if(range === 'year') {
        return this.get('year') == new Date(date).getFullYear();
      } else {
        return this._value == date;
      }
    },
    weeksInMonth() {
      const start = new Moment(this._value).first('date');
      const end = new Moment(this._value).last('date');
      let initialValue = 0;

      for (let date = start; date.date() <= end.date(); date.add(1)) {
        if (date.day() === 0) {
          initialValue++;
        }
      }

      return initialValue;
    }
  }
}
